<template>
  <HeaderFirst></HeaderFirst>
  <div class="container clearfix" style="margin-top: 15px">
    <HelpMenu class="page-col-sm"></HelpMenu>
    <div class="page-col-lg">
      <div class="npanel">
        <div class="npanel-heading">
          <h4>隐私权政策</h4>
        </div>
        <div data-v-2d800312="" class="npanel-body spage">
          <p style="
              margin: 0pt 0pt 0.0001pt;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
              background: #ffffff;
              text-align: center;
            ">
            <strong>
                <span
                style="
                  font-family: 黑体;
                  color: #333333;
                  font-size: 10.5pt;
                  background-image: initial;
                  background-position: initial;
                  background-size: initial;
                  background-repeat: initial;
                  background-attachment: initial;
                  background-origin: initial;
                  background-clip: initial;
                ">经济运行平台隐私权政策</span>
            </strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: right;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 18pt;
              background: #ffffff;
            ">
            <span
              style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              ">更新日期：&nbsp;</span>
              <span style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              ">2023</span>
              <span style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              ">年&nbsp;</span>
              <span style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              ">10</span>
              <span style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >月&nbsp;</span>
              <span style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >21</span>
              <span style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >日</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: right;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 18pt;
              background: #ffffff;
            ">
            <span  style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              ">生效日期：&nbsp;</span>
              <span  style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              ">2023</span>
              <span style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              ">年&nbsp;</span>
              <span style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >10</span>
              <span style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              "
              >月&nbsp;</span>
              <span  style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              ">21</span>
              <span  style="
                font-family: 黑体;
                color: #333333;
                font-size: 9pt;
                background-image: initial;
                background-position: initial;
                background-size: initial;
                background-repeat: initial;
                background-attachment: initial;
                background-origin: initial;
                background-clip: initial;
              ">日</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: right;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 18pt;
              background: #ffffff;
            ">
            &nbsp;
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">本《隐私权政策》
            （以下简称“本政策”或“本隐私权政策”）
            适用于
            北京易可诺信息科技有限公司（以下简称“经济运行平台”或“我们”）通过
            网站、客户端、小程序以及随技术发展出现的新形态向
            用户
            （以下或称“您”）提供的各项产品和服务。针对某些特定的产品/服务，我们
            可能还将制定单独的隐私权政策，向您说明这些产品/服务的特殊政策。如相关特定的隐私权政策与本政策有不一致之处，适用该特定隐私权政策。</span>
            
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">经济运行平台非常重视保护用户的个人信息及隐私，
                我们深知个人信息对您的重要性，我们也深知您向经济运行平台提供各种信息是基于对经济运行平台的完全信任，
                我们将严格按照法律法规要求，采取相应的安全保护措施来保护您的个人信息安全。
                基于此，我们制定本政策，希望通过本政策向您清晰地介绍在使用我们的产品/服务时，
                我们会如何收集、使用、共享、存储、保护您的个人信息，以及我们为您提供的访问、更正、删除这些信息的方式。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong>
                <span style="font-family: 黑体; font-size: 10.5pt">
                在使用经济运行平台各项产品和服务前，请您务必仔细阅读并透彻理解本政策，特别应重点阅读我们以黑体加粗或加下划线标识的条款，在确认充分理解并同意后再开始使用经济运行平台服务。一旦您使用或继续使用我们的产品、服务时，即意味着您同意我们按照本政策处理您的相关信息。
                </span>
            </strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">
              如对本政策内容有任何疑问、意见或建议，您可通过经济运行平台公布的联系方式与我们联系。经济运行平台的宗旨是以诚信为本，我们一定会以负责的态度对待您提交给我们的信息。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">通过本隐私权政策您主要可以了解如下内容：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">•我们如何收集和使用您的个人信息</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">•我们如何使用Cookie及同类技术</span>
          </p>
          <p
            style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">•我们如何公开、共享与转让您的个人信息</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">•我们如何存储、保护您的个人信息</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">•我们如何保护未成年人的个人信息</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">•我们如何保障您的信息权利</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">•本隐私权政策如何变更</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">•如何联系我们</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">一、我们如何收集和使用您的个人信息</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">1、收集、使用的基本原则</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">我们会遵循合法、正当、必要的原则，出于向您提供相关产品和服务的目的向您收集和处理您的个人信息，并向您披露。我们尽可能全面、完整、及时地向您披露您的个人信息的收集和使用情况，若您发现我们的披露存在错误或遗漏，请通过本政策所述的联系方式与我们联系。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">在您使用我们的产品/服务时，您需要/可能需要授权我们收集和使用的您的个人信息包括如下两种：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">（1）为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务；</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">（2）为实现向您提供我们产品及/或服务的附加功能，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但不会影响您使用我们的基本功能。</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">2、基本业务功能中，您需要授权我们收集和使用您个人信息的情形：</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">为了实现经济运行平台产品/服务的下述基本功能，我们将收集和使用您的如下个人信息：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">（1）帮助您成为我们的注册用户</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">为遵守法律法规的要求，以及向您提供更便捷的服务，当您注册成为经济运行平台用户时，您需要提供手机号码以创建经济运行平台账号
                我们收集您的上述信息是为了帮助您完成经济运行平台账户的注册，保护您的账户安全，并正常向您提供服务。</span>
            </strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt;">在提供账号信息的过程中，您还可以根据您的自身需求选择向我们提供您的昵称、头像、邮箱、性别等，以完善您的个人资料。如果您不提供该等信息，不会影响您享受经济运行平台服务的基本功能。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt;">如您选择授权使用第三方账号登录时，我们会根据您的授权从第三方获取您共享的账号信息（如头像、昵称等信息，具体以您的授权内容为准）与您的经济运行平台账号进行绑定用于快捷登录，我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。如您拒绝授权此类信息的，您将无法使用第三方账户进行登录，但不影响您享受经济运行平台为您提供的其他产品和服务。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">（2）响应您的产品服务需求</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">当您进行经济信息查询并想要获取查询报告时，我们需要您填写邮箱地址。如您不同意我们收集收件信息，您可能因无法获得经济信息查询报告而不能正常使用我们的服务，但不影响您仅进行经济信息查询操作。</span>
          </p>
          
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong><span style="font-family: 黑体; font-size: 10.5pt">（3）实现产品/服务的安全保障</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">在您使用经济运行平台服务过程中，为识别账号异常状态、了解产品适配性、向您提供更契合您需求的页面展示和搜索结果，我们可能会自动收集您的使用情况并存储为网络日志信息，包括：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">①</span><span>&nbsp;设备信息：为便于设备识别、进行数据统计和分析，保障网络访问及服务运营的安全和质量，我们会根据您在访问及/或使用中的具体操作及后台状态下，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、MAC地址及IMEI、MEID、IDFA、OAID设备标识符、Android
              ID、IP、运行中的进程、设备环境、移动应用列表软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站传感器信息）。</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">②</span><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;服务日志信息：当您使用经济运行平台服务时，我们会自动收集您对我们服务的详细使用情况，作为服务日志保存，包括浏览、搜索查询、监控经济、关注企业、IP地址、浏览器类型、使用语言、访问日期和时间等。</span>
            </strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">请注意，单独的设备信息、服务日志信息无法识别特定自然人身份。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息进行匿名处理。</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">（4）为您提供下单及订单管理功能</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">您在</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台</span
            ><span style="font-family: 黑体; font-size: 10.5pt">下单</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >订购会员产品、报告下载导出</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >等服务时，您需要根据所选择的服务类型提供联系人信息（包括但不限于姓名、电话号码、</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >邮箱、证件号码</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >等，具体以您的授权内容为准）。我们会为您生成购买该</span
            ><span style="font-family: 黑体; font-size: 10.5pt">产品/</span
            ><span style="font-family: 黑体; font-size: 10.5pt">服务的</span
            ><span style="font-family: 黑体; font-size: 10.5pt">具体</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >订单，该订单中会载明您所订购的</span
            ><span style="font-family: 黑体; font-size: 10.5pt">产品/</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >服务信息、具体订单号、订单创建时间、您支付的金额。我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客服与售后服务及其他我们明确告知的</span
            ><span style="font-family: 黑体; font-size: 10.5pt">其他</span
            ><span style="font-family: 黑体; font-size: 10.5pt">目的。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >如您需要纸质发票，为了给您邮寄发票或其他文件，还需您填写收件人姓名、收件地址、收件人联系电话，以便于完成快递配送。</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
          <strong><span style="font-family: 黑体; font-size: 10.5pt">（5）帮助您完成服务订单支付</span></strong>
           
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >您在经济运行平台上进行订单支付时，</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >您需要提供第三方支付平台（支付宝、微信支付）账户并选择付款方式，我们会将您</span
            ><span style="font-family: 黑体; font-size: 10.5pt">脱敏后</span
            ><span style="font-family: 黑体; font-size: 10.5pt">的用户</span
            ><span style="font-family: 黑体; font-size: 10.5pt">信息</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >、对应的第三方支付平台账户名、订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息通过在应用程序中嵌入的SDK方式与第三方支付平台共享，以确认您的支付指令并帮助您完成支付，前述第三方支付平台将获得您连接网络的权限、手机信息权限，并获取网络状态和wifi状态。</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong><span style="font-family: 黑体; font-size: 10.5pt">（6）响应您的客服需求</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">当您使用我们的客户服务或其他用户响应功能时，为了您的账号与系统安全，我们可能需要您先行提供账号信息，并与您之前的个人信息相匹配以验证您的用户身份。同时，我们可能还会需要收集您的如下个人信息：联系方式（您与我们联系时使用的电话号码/电子邮箱或您向我们主动提供的其他联系方式）、您与我们的沟通信息（包括文字/图片/音视频/通话记录/通话录音）及与您需求相关联的其他必要信息。我们收集这些信息是为了调查事实与帮助您解决问题，如您拒绝提供可能导致您无法使用我们的客服等用户响应功能。</span>
          </p>
          
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">4、收集和使用个人信息的其他规则</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（1）您可以通过经济运行平台为其他人订购经济运行平台的各类产品/服务，并提供该实际服务接收人的有关信息。</span>
            <strong><span style="font-family: 黑体; font-size: 10.5pt">若您提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保已经取得合法的授权。</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（2）若我们将信息用于本隐私权政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，会单独征求您的授权同意。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt"
              >（3）请您理解并知悉，根据相关法律法规规定，我们在以下情况下收集、使用您的个人信息无需您的授权同意：</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">①</span><span style="font-family: 黑体; font-size: 10.5pt">为履行法定职责或者法定义务所必需；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">②</span><span style="font-family: 黑体; font-size: 10.5pt">为应对突发公共卫生事件，或者紧急情况下为保护您的生命健康和财产安全所必需；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">③</span>
            <strong><span style="font-family: 黑体; font-size: 10.5pt">在合理范围内使用个人信息主体自行向社会公众公开或其他已经合法公开的个人信息，但您明确拒绝或对个人权益有重大影响的除外；</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">④</span><span style="font-family: 黑体; font-size: 10.5pt">根据个人信息主体要求签订和履行合同所必需的；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">⑤</span><span style="font-family: 黑体; font-size: 10.5pt">为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">⑥</span><span style="font-family: 黑体; font-size: 10.5pt">法律、法规或监管政策规定的其他情形。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（4）</span>
            <strong><span style="font-family: 黑体; font-size: 10.5pt"
                >请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善我们的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。</span
              ></strong>
              <span style="font-family: 黑体; font-size: 10.5pt">当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私权政策处理与保护。&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">二、我们如何使用Cookie及同类技术</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >1、Cookie&nbsp;是指从网络服务器发送到您的浏览器，并储存到电脑硬盘上的少量数据，通常包括一些特殊标识符、站点名称以及一些号码和字符，是用来记录您的使用者ID。</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >2、为给您提供更便捷的访问体验、更周到的服务，实现您联机体验的需求，使您获得更轻松的访问体验，我们会在您的计算机或移动设备上发送一个或多个名为Cookies的小数据文件或同类技术文件，指定给您的Cookies是唯一的，它只能被将Cookies发布给您的域中的Web服务器读取。我们向您发送Cookies是为了简化您重复登录的步骤、帮助我们记住您的设备和使用服务偏好、帮助判断您的登录状态以及账户或数据安全。</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt"
              >3、我们不会将Cookies用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies、清除计算机</span
            ><span style="font-family: 黑体; font-size: 10.5pt">或移动设备</span
            ><span style="font-family: 黑体; font-size: 10.5pt"
              >上保存的所有&nbsp;Cookies。大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookies。另外，您也可以清除软件内保存的所有Cookies，但如果您这么做，您可能需要在每一次访问时亲自更改用户设置，而且之前所记录的您的相应信息也均会被删除，并且可能会对您所使用的服务的安全性有一定影响。</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >三、我们如何公开、共享与转让</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >您的个人信息</span
              ></strong
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">1、公开</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">我们仅会在以下情况下，公开披露您的个人信息：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">(1)已获得用户的明确授权；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">(2)根据有关法律、法规的要求或强制性的行政执法或司法机关要求。在符合法律法规的前提下，当我们收到该类披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">2、共享</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">在我们向您提供产品/服务时，我们会采取严格的安全措施，不会与其他任何公司、组织和个人共享您的个人信息。但以下情况除外：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（1）履行法定义务所必需的共享：我们可能会根据法律法规规定、诉讼、争议解决的必要，或按行政、司法机关依法提出的要求，以及其他法定义务履行的必需，共享您的个人信息。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（2）取得您同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（3）为订立、履行您作为一方当事人的合同所必需的情况下的共享：您通过经济运行平台购买产品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关产品或服务提供者，以实现您的交易、配送及售后服务需求。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">（4）为完成产品功能/服务所必须情况下的共享：当您使用经济运行平台产品/服务时，我们的部分服务或功能，需要使用第三方
                提供的SDK来协助完成，或者由第三方提供的SDK直接提供相关服务。</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            " >
            <span style="font-family: 黑体; font-size: 10.5pt">为了您的信息安全，我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息。对我们仅为实现本政策中声明的目的与之共享个人信息的第三方，其只能接触到其履行职责所需信息，且我们会对第三方进行严格的安全检测，并对其约定严格的数据安全保密义务，确保其会严格遵守我们的数据隐私和安全要求。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">3、转让</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（1）在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（2）在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私权政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">4、共享、转让、公开披露个人信息时事先征得授权同意的例外</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（1）以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">①</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt" >与我们履行法律法规规定的义务相关的；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">②</span>
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
            <span style="font-family: 黑体; font-size: 10.5pt" >与国家安全、国防安全直接相关的；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">③</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">与公共安全、公共卫生、重大公共利益直接相关的；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">④</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">与犯罪侦查、起诉、审判和判决执行等直接相关的；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">⑤</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">⑥</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">您自行向社会公众公开的个人信息；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">⑦</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">⑧</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">有权机关的要求、法律法规等规定的其他情形。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">（2）根据法律规定，共享、转让、公开披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，我们对此类数据的处理将无需另行向您通知并征得您的同意。</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">四、我们如何保护、存储您的个人信息</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">1、个人信息的保护</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（1）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止其遭到未经授权的访问、使用、修改、公开披露、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息，例如，构建完整先进的数据安全保护体系、使用加密技术确保数据的保密性、使用受信赖的保护机制防止数据遭到恶意攻击、我们会部署访问控制机制确保只有授权人员才可访问个人信息、举办数据安全和隐私保护培训课程、加强员工对于个人信息保护重要性的认识。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（2）我们将采取严格的措施保护您的隐私，我们的重要信息系统已经通过了网络安全等级保护的三级测评，确保您的隐私权不受任何侵犯。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（3）互联网环境并非百分之百安全，我们将尽力确保您发送给我们的个人信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，从而导致您的合法权益受损，我们会及时启动应急预案，采取合理救济措施，以此尽可能降低对您个人信息和其他权益的影响。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（4）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议以及对您的补救措施等。我们会及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（5）若您发现您的个人信息泄露时，请您立即通过本政策所述的联系方式与我们联系，以便我们及时采取相应措施。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">2、个人信息的存储</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（1）</span>
            <span style="font-family: 黑体; font-size: 10.5pt">我们收集信息的前提为向您提供更为优质的服务，并避免收集与向您提供服务无关的信息。我们将收集您的信息储存在中华人民共和国境内的服务器上，并且在为您提供服务的所必要的期限保留您的信息。如需跨境存储或向境外提供个人信息的，我们会单独向您明确告知（包括出境的目的、接收方、使用方式与范围、使用内容、安全保障措施、安全风险等）并再次征得您的授权同意，并严格要求接收方按照本政策以及法律法规相关要求来处理您的个人信息；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（2）经济运行平台不会超期储存您的信息，当您选择退出、终止服务，注销账户或是经济运行平台不再向您提供服务，经济运行平台将对您的信息进行删除或匿名化处理。但法律法规另有规定的除外（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              line-height: 24pt;
            " >
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt" >&nbsp;五、我们如何保护未成年人的个人信息</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt"
              >1、&nbsp;经济运行平台非常重视对未成年人个人信息的保护。当您在非登录状态下浏览我们的产品时，我们仅会收集有关您在使用具体产品过程中的个人当前设备状态信息及必要的个人常用设备信息，且受现有技术限制，我们无法判断您是否为未满14周岁的儿童，但我们会严格按照您的授权同意范围使用该等信息，并按照本政策的承诺对您的个人信息予以保护。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">2、在您注册登录账户并填写年龄相关信息或进行实名验证时，我们会收集到您的年龄信息并可识别您是否为</span
            ><span style="font-family: 黑体; font-size: 10.5pt">未成年人。</span>
            <strong><span style="font-family: 黑体; font-size: 10.5pt" >鉴于我们的产品主要面向成年人用户使用，如果您未满14周岁且未经父母或监护人的同意，您不应创建自己的个人账户或使用我们的服务。</span></strong>
            <span style="font-family: 黑体; font-size: 10.5pt">对于经父母或监护人同意而收集的未满14周岁的未成年人的个人信息的情况，我们仅会在受到相关法律法规允许、父母或监护人明确同意或保护未成年人所必要的情况下使用或公开披露此信息。同时，按照法律法规的规定，14周岁以下未成年人（儿童）的个人信息属于个人敏感信息。我们针对14周岁以下儿童个人信息的处理将按照个人敏感信息的有关法律规定和相关国家标准与要求进行。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">3、如果我们发现自己在未事先获得监护人同意的情况下收集了未满14周岁的未成年人的个人信息，我们将删除或匿名化处理相关数据。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            " >
            <strong><span style="font-family: 黑体; font-size: 10.5pt">六、我们如何保障您的信息权利</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">按照相关的法律、法规规定，我们将努力保障您在我们的个人信息处理活动中享有的权利，包括但不限于：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">1、查询、访问您的个人信息</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">您可以通过以下方式查询您的信息：在使用经济运行平台网页端时，您可以点击页面右上角您的用户名链接按钮进行查询；如您需要查询您的下载记录、浏览历史、关注</span
            ><span style="font-family: 黑体; font-size: 10.5pt">信息、笔记信息、订单信息，请在右上角您的用户名悬停菜单中，点击对应按钮进行查询。在使用经济运行平台小程序时，如您需要查询您的头像、昵称、浏览历史、关注信息、订单信息，请进入“我的”页面进行查询。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">2、改变您的个人信息授权范围</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">每个业务功能需要相应的个人信息才能得以完成。&nbsp;</span
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >对于为实现经济运行平台附加功能相关个人信息的收集和使用，您可以随时给予或收回您的授权同意。</span></strong>
            <span style="font-family: 黑体; font-size: 10.5pt">您可以与经济运行平台客服联系，或改变您的移动设备设置给予或收回您的授权同意。当您收回同意后，我们将不再处理相应的个人信息。但您
            收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">3、个人信息更新</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（1）为了使您能和您的联系人保持长久的联系，在您的个人资料有变动的情况下，您可以更新和修改您的个人资料以使其有效。您在任何时候都能非常容易地使用您的注册名与密码登录。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（2）当您发现我们处理的关于您的个人信息有误时，您可以登录经济运行平台网页端，前往页面右上角，点击“我的主页”页面修改；&nbsp;如您需要管理您的下载记录、浏览历史、关注信息、笔记信息，请前往网页端页面，并在右上角您的用户名悬停菜单中选择相应栏目进行修改。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">4、个人信息删除</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt" >一般而言，我们只会在法律法规规定或必需且最短的时间内保存您的个人信息。为便于您行使您的删除权，我们为您提供了在线自行删除和向我们提出删除申请两种方式。</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;">
            <span style="font-family: 黑体; font-size: 10.5pt">（1）您可以自行进入经济运行平台网页端，前往网页端页面右上角，点击您的用户名链接按钮并在弹出页面删除；如果您发现不能删除信息，您可联系经济运行平台（见隐私权政策第八条）。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（2）在以下情形中，您可以通过本政策所述的联系方式向我们提出删除个人信息的请求：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">①</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">如果经济运行平台未经您同意收集、使用、处理您的信息；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">②</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">如果经济运行平台收集、使用、处理信息违反了与您的约定；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">③</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">如果您不再使用经济运行平台的服务，或是您已经注销了经济运行平台账户；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">④</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台不再为您提供服务。</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt" >（3）当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。</span></strong>
          </p>
          
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong><span style="font-family: 黑体; font-size: 10.5pt">5、账户注销</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">您可以通过以下方式申请注销您的账号：</span>
          </p>
          
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt"
              >（1）登录网页端，通过“我的主页-个人信息-帐号信息-注销账号”提交账号注销申请；</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（2）登录经济运行平台小程序，通过“我的-注销账号”提交账号注销申请；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（3）您可以向经济运行平台客服邮箱发送电子邮件，提交您注销账号的需求，我们会协助您申请注销您的账号。</span >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息或对您的个人信息进行匿名化处理，但法律法规另有规定的除外。</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">6、响应您的上述请求</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（1）</span
            ><span style="font-family: 黑体; font-size: 10.5pt">为了保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（2）您的请求我们将在十五个工作日内做出答复。如您对我们的答复不满意，还可以通过本政策所述的联系方式向我们投诉。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（3）对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">（4）在以下情形中，按照法律法规要求，我们将无法响应您的请求：&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">①</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">与我们履行法律法规规定的义务相关的；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">②</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">与国家安全、国防安全直接相关的；</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">③</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">与公共安全、公共卫生、重大公共利益直接相关的；</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">④</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">与刑事侦查、起诉、审判和执行判决等直接相关的；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">⑤</span
            ><span style="font-family: 黑体; font-size: 10.5pt">我们有充分证据表明您存在主观恶意或滥用权利的；</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">⑥</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">⑦</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">⑧</span
            ><em
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >&nbsp;</span
              ></em
            ><span style="font-family: 黑体; font-size: 10.5pt">涉及商业秘密的；</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <span style="font-family: 黑体; font-size: 10.5pt">⑨</span
            ><span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span
            ><span style="font-family: 黑体; font-size: 10.5pt">法律法规等规定的其他情形。</span
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong><span style="font-family: 黑体; font-size: 10.5pt">七、本隐私权政策如何变更</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">1、基于法律法规、产品功能、服务内容等在未来可能不断变更，经济运行平台可能会对隐私权政策进行相应更新。未经您明确同意，我们不会削减您按照本《隐私权政策》所应享有的权利。</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">2、如更新本隐私权政策的，我们会通过站内提示或是其他显著方式通知您。若您继续使用我们的服务，即表示同意接受修订后的隐私权政策并受之约束。</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">&nbsp;</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong><span style="font-family: 黑体; font-size: 10.5pt">八、如何联系我们</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">您可以通过以下方式与我们联系，我们将在收到您的需求后，在15个工作日内予以回复：</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <span style="font-family: 黑体; font-size: 10.5pt">1、如对本政策内容有任何疑问、意见或建议，您可通过经济运行平台客服与我们联系；</span>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            ">
            <strong><span style="font-family: 黑体; font-size: 10.5pt">2、如发现个人信息可能被泄露，您可以通过经济运行平台客服进行投诉举报；</span></strong>
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong><span style="font-family: 黑体; font-size: 10.5pt">经济运行平台客服邮箱：</span></strong>
            <a href="mailto:【market@chinaecono.com】；"><strong><u
                  ><span
                    class="15"
                    style="
                      font-family: 黑体;
                      color: #333333;
                      background: #ffffff;
                    "
                    >【market@chinaecono.com】</span
                  ></u
                ></strong
              ></a
            ><strong
              ><u
                ><span
                  class="15"
                  style="font-family: 黑体; color: #333333; background: #ffffff"
                  >。</span
                ></u
              ></strong
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >经济运行平台电话：15324076890。</span
              ></strong
            >
          </p>
          <p style="
              margin: 0pt 0pt 0.0001pt;
              text-align: justify;
              font-family: Calibri;
              font-size: 10.5pt;
              text-indent: 21pt;
              line-height: 24pt;
            "
          >
            <strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >经济运行平台</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >联系地址：</span
              ></strong
            ><strong
              ><span style="font-family: 黑体; font-size: 10.5pt"
                >北京市海淀区中关村大街1号17层M22号。</span
              ></strong
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  <FooterNew></FooterNew>
</template>
<script>
import HeaderFirst from '@/components/HeaderFirst.vue'
import HelpMenu from '@/components/HelpMenu.vue'
import FooterNew from '@/components/FooterNew.vue'
//import { ref } from '@vue/reactivity'
export default {
  components: {
    HeaderFirst,
    HelpMenu,
    FooterNew,
  },
  setup() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.page-col-sm {
  position: relative;
  background-color: #fff;
  min-height: 1px;
  float: left;
  width: 200px;
  min-height: 650px;
}
.page-col-lg {
  width: 1048px;
  position: relative;
  overflow: hidden;
}
.npanel {
  border-radius: 4px;
  border: none;
  min-height: 650px;
  background: #fff;
  box-shadow: none;
  border: solid 1px #eee;
  margin-bottom: 15px;
  margin-left: 15px;
}
.npanel-heading {
  color: #333;
  border-bottom: none;
  h4 {
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    display: inline-block;
    line-height: 50px;
    margin-left: 15px;
    font-size: 15px;
  }
}
.npanel-body {
  padding: 20px 15px;
  position: relative;
  &:before {
    content: ' ';
    position: absolute;
    height: 1px;
    left: 15px;
    right: 15px;
    top: -1px;
    background: $bordercolor;
    display: block;
    z-index: 2;
  }
  p {
    margin: 0 0 10px;
    line-height: 25px;
  }
}
</style>